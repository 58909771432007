import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {_track} from "../util"; 

const SearchFilters = ({
   setGenreFilter, 
   setRegionFilter,
   showFilters,
   showRegionFilter, 
   setShowRegionFilter,
   showGenreFilter, 
   setShowGenreFilter
}) => {  

  const regionListClickHandler = (e) => {
    const regionFilterText = e.target.text;
    const regionButton = document.querySelector("#filter-region-btn"); 

    if(regionFilterText === "Όλες") {
      setRegionFilter(null);
      regionButton.innerText = "Περιοχή"
    }
    else {
      setRegionFilter(regionFilterText);
      regionButton.innerText = regionFilterText;
    }
    _track('click_select_regionfilter', {
        'region_filter':regionFilterText
      });    
    
  }
  const genreListClickHandler = (e) => {
    const genreFilterText = e.target.text;
    const genreButton = document.querySelector("#filter-genre-btn"); 
    

    if(genreFilterText === "Όλα") {
      setGenreFilter(null);
      genreButton.innerText = "Είδος";
    }
    else {
      setGenreFilter(genreFilterText);
      genreButton.innerText = genreFilterText;
    }
    _track('click_select_genrefilter', {
        'genre_filter':genreFilterText
      });    
  }
  
  const regionBtnClickHandler = () => {  
    setShowRegionFilter(!showRegionFilter);
    setShowGenreFilter(false);
  }

  const genreBtnClickHandler = () => {  
    setShowGenreFilter(!showGenreFilter);
    setShowRegionFilter(false);
  }

  return (
      <div className={`filters ${showFilters ? " " : "hide"}`}>
        <div className="filter-region">
          <button 
            id="filter-region-btn" 
            onClick = {regionBtnClickHandler}
          >Περιοχή 
            <span>        
              <FontAwesomeIcon
                className="filter-arrow"
                icon={faAngleDown}
              />
            </span>
          </button> 
          <div id="filter-region-dropdown">
            <ul 
              onClick={regionListClickHandler}
              className={`${showRegionFilter ? "show-filter" : "hide-filter"}`}
            >
              <li><a href="#/">Όλες</a></li>
              <li><a href="#/">Αθήνα</a></li>
              <li><a href="#/">Θεσσαλονίκη</a></li>
              {/*<li><a href="#/">Πελοπόνησος</a></li>
              <li><a href="#/">Μακεδονία</a></li>
              <li><a href="#/">Ήπειρος</a></li>
              <li><a href="#/">Θράκη</a></li>
              <li><a href="#/">Θεσσαλία</a></li>
              <li><a href="#/">Στερεά Ελλάδα</a></li>
              <li><a href="#/">Αιγαίο</a></li>
              <li><a href="#/">Ιόνιο</a></li>
              <li><a href="#/">Κρήτη</a></li>*/}
            </ul>
          </div>
        </div>
        <div className="filter-genre">
          <button
            id="filter-genre-btn"
            onClick={genreBtnClickHandler}
            >Είδος
            <span>        
              <FontAwesomeIcon
                className="filter-arrow"
                icon={faAngleDown}
              />
            </span>                
          </button>
          <div id="filter-genre-dropdown"> 
            <ul 
              onClick={genreListClickHandler}
              className={`${showGenreFilter ? "show-filter" : "hide-hilter"}`}
            >
              <li><a href="#/">Όλα</a></li>
              <li><a href="#/">Αθλητικά</a></li>
              <li><a href="#/">Δημοφιλή</a></li>
              <li><a href="#/">Διεθνή</a></li>
              <li><a href="#/">Ειδήσεις</a></li>
              <li><a href="#/">Έντεχνα / Παραδοσιακά</a></li>
              <li><a href="#/">Θρησκευτική</a></li>
              <li><a href="#/">Λαϊκά</a></li>
              <li><a href="#/">Ροκ</a></li>            
            </ul>
          </div>
        </div>        
      </div>
  );
};

export default SearchFilters;
