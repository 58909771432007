import React, {useState} from "react";
import Search from "./Search";
import SearchFilters from "./SearchFilters"
import LibraryStation from "./LibraryStation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";
import {_track} from "../util"; 
import {Link} from "react-router-dom";
import {normalizeGreek} from "../util";
var _ = require('lodash');

const Library = ({
  stations,
  setStations,
  searchQuery,
  setSearchQuery,
  setCurrentStation,
  genreFilter,
  regionFilter,
  setRegionFilter, 
  setGenreFilter,
  showGenreFilter,
  setShowGenreFilter,
  showRegionFilter,
  setShowRegionFilter,
  stationInfo,
  setStationInfo,
  audioRef,
  isPlaying,
  setIsPlaying,
  libraryStatus,
  setLibraryStatus
}) => {
  
  const [showFilters, setShowFilters] = useState(false);
  const [scrollDepth, setScrollDepth] = useState(0);

  const clearFiltersHandler = () => {
    const genreButton = document.querySelector("#filter-genre-btn"); 
    const regionButton = document.querySelector("#filter-region-btn"); 
    regionButton.innerText = "Περιοχή"
    genreButton.innerText = "Είδος";
    _track('click_clear_filters', {});
    setRegionFilter(null);
    setGenreFilter(null);
  }

  const hamburgerHandler = (e) => {
    setLibraryStatus(!libraryStatus);
    _track('click_close_library', {});
  }

  const scrollHandler = (e) => {
    setScrollDepth(e.target.scrollTop);
  }

  const scrollTopHandler = (e) => {
    var library = document.querySelector('div.library-stations'); 
    library.scrollTop = 0;
  }

  var filteredStations = _.map(stations, function(station) {
      const stationName = station.name.toLowerCase();
      const stationEnName = station.en_name ? station.en_name.toLowerCase() : null;

      if ((_.includes(stationName, searchQuery) 
        || _.includes(normalizeGreek(stationName), searchQuery)
        || (stationEnName && _.includes(stationEnName, searchQuery)))   
        && (genreFilter===null || _.eq(station.genre,genreFilter))
        && (regionFilter===null || _.eq(station.region, regionFilter)))
          return station;
  }); 
  filteredStations = _.without(filteredStations, undefined);
  filteredStations = _.orderBy(filteredStations, ['favorite','en_name'],['desc','asc']);

  return (
    <div className={`library ${libraryStatus ? "active-library" : " "}`}>
      <div className={`library-top-section ${showFilters ? "active-filters" : " "}`}>
        <div className="logo-section">
          <img id="logo" src="/logo-v1.png" alt="fastradio.gr logo"></img>
          <FontAwesomeIcon
            onClick={hamburgerHandler}
            className="hamburger"
            icon={faBars}
          />
        </div>
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}  
          libraryStatus={libraryStatus}
          setLibraryStatus={setLibraryStatus}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <SearchFilters 
          setRegionFilter={setRegionFilter}
          setGenreFilter={setGenreFilter}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          showGenreFilter={showGenreFilter}
          setShowGenreFilter={setShowGenreFilter}
          showRegionFilter={showRegionFilter}
          setShowRegionFilter={setShowRegionFilter}
        />
      </div>
      <div 
        className={`no-results ${filteredStations.length === 0 ? "" : "hide"}`}
      >
        <h2>Δε βρέθηκαν σταθμοί με βάση τα κριτήρια αναζήτησης.        
          <a 
            href="/#" 
            onClick={clearFiltersHandler}
            className={`${genreFilter == null && regionFilter == null ? "hide" : ""}`}
          > Καθαρισμός φίλτρων αναζήτησης.
        </a>
        </h2>
      </div>
      <div 
        className="library-stations"
        onScroll={scrollHandler}
      >
        {filteredStations.map((station) => (
          <Link to={`/station/${station.alias == null ? "undefined" : station.alias}`}>
          <LibraryStation
              searchQuery={searchQuery}
              stations={stations}
              setStations={setStations}
              cover={station.cover}
              name={station.name}
              genre={station.genre}
              active={station.active}
              key={station.id}
              id={station.id}
              setCurrentStation={setCurrentStation}
              stationInfo={stationInfo}
              setStationInfo={setStationInfo}
              audioRef={audioRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              favorite={station.favorite}
            />
          </Link>
        ))}
        <button
          className={scrollDepth > 500 ? "scrollTop" : "hide"}
          onClick={scrollTopHandler}
        >
          <span>        
              <FontAwesomeIcon
                className={scrollDepth > 500 ? "scrollTop-icon" : "hide"}
                icon={faChevronCircleUp}
              />
          </span>                
        </button>

      </div>
    </div>
  );
};

export default Library;
