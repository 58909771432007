import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch, faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons"
import {faHeart} from "@fortawesome/free-regular-svg-icons"
import {
  faPlay,
  faPause
} from "@fortawesome/free-solid-svg-icons";
import {_track} from "../util";
var _ = require('lodash');

const MobilePlayer = ({
  isPlaying,
  setIsPlaying,
  audioRef,
  currentStation,
  stations,
  setStations,
  stationInfo,
  searchQuery,
  setLibraryStatus,
  libraryStatus
}) => {

  const favoriteStationHandler = () => {
    
    var newStations = [];

    if (currentStation.favorite === false) {
      currentStation.favorite = true;
      
      newStations = stations.map((station) => {
      if (currentStation.id === station.id) {
        return {
          ...station,
          favorite:true,
        }
      }
      else {
        return station;
      }
      })
  }
  else
  {
      currentStation.favorite = false;
      newStations = stations.map((station) => {
      if (currentStation.id === station.id) {
        return {
          ...station,
          favorite:false,
        }
      }
      else {
        return station;
      }
      })
  }
  _track('click_favorite_station', {
         'station':currentStation.name,
         'favorite':currentStation.favorite,
         'genre':currentStation.genre,
         'region':currentStation.region,
         'station_id':currentStation.id,
         'search_query':searchQuery
   });

  newStations = _.orderBy(newStations, ['favorite','en_name'],['desc','asc']);
  setStations(newStations);
  }

  const playStationHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
      _track('click_pause_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      setIsPlaying(!isPlaying);
    } else {
      audioRef.current.load();
      audioRef.current.play();
      _track('click_play_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      setIsPlaying(!isPlaying);
    }
  };

  const clickHandler = (e) => {
      setLibraryStatus(!libraryStatus);
  }

  return (
    <div 
      className="player-mobile">
      <div className="active-song-mobile">   
        <img
          className={isPlaying && stationInfo.currentTime > 0 ? "rotateStation" : ""}
          src={currentStation.cover}
          alt={currentStation.en_name}
          onClick={clickHandler}
        />
        <div 
          className="active-song-mobile-container"
          onClick={clickHandler}
        >
          <h2>{currentStation.name.length >=16 ? currentStation.name.substring(0,16) + "..." : currentStation.name}</h2>      
          <h3>{currentStation.genre}</h3>  
        </div>
        <FontAwesomeIcon
          className={`favorite-control-mobile ${currentStation.favorite ? "heart-solid" : "heart-empty"}`}  
          icon={currentStation.favorite ? faHeartSolid : faHeart}
          onClick={favoriteStationHandler}
        /> 
      </div>
      <div className="play-control-mobile">  
        <FontAwesomeIcon
          onClick={playStationHandler}
          className={`play ${isPlaying && stationInfo.currentTime === 0 ? "fa-spin" : " "}`} 
          icon={isPlaying ? (stationInfo.currentTime === 0 ? faCircleNotch : faPause) : faPlay}
        />
      </div>
    </div>
  );
};

export default MobilePlayer;
