import React from "react";

const PreloadStations = () => {
  
    return (
      <div className="preload-stations">

        <audio
            controls
            preload="auto"
            src="https://stream.radiojar.com/menta"
          ></audio>
        
      </div>
    );
  };
  
  export default PreloadStations;
  