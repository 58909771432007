import { v4 as uuidv4 } from "uuid";

function chillHop() {

  return [
  {
  "name": "A 100 FM 107.4",
  "en_name": "A 100 FM 107.4",
  "alias": "a100-fm",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://bestgreek.info:8104/;listen.pls",
  "cover": "/assets/stations/thumb/athens/a100.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Alpha 98.9 Αθήνα",
  "en_name": "Alpha 98.9 Athina",    
  "alias": "alpha-athina",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/xcdg6yggzfeuv",
  "cover": "/assets/stations/thumb/athens/alpha989.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Art FM 90.6",
  "en_name": "Art FM 90.6",  
  "alias":"art-fm",  
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://167.114.246.249:8023/stream",
  "cover": "/assets/stations/thumb/athens/artfm906.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Athens 106.5",
  "en_name": "Athens 106.5",  
  "alias": "athens-106",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://89.163.217.252:9400/stream",
  "cover": "/assets/stations/thumb/athens/athens-1065.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Athens DJ 95.2",
  "en_name": "Athens DJ 95.2",
  "alias": "athens-dj",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/athensdeejay",
  "cover": "/assets/stations/thumb/athens/athens-deejay.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Athens Voice 102.5",
  "en_name": "Athens Voice 102.5",
  "alias": "athens-voice",
  "genre": "Λαϊκά",
  "region": "Αθήνα",
  "audio": "http://nitro.live24.gr/nitro4555",
  "cover": "/assets/stations/thumb/athens/athens-voice-radio.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Atlantis FM 105.2",
  "en_name": "Atlantis FM 105.2",
  "alias": "atlantis-fm",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "http://85.10.206.222:8012/stream",
  "cover": "/assets/stations/thumb/athens/atlantisradio.png",
  "favorite": false,
  "encrypted": false,  
  "id": uuidv4()
  },
  {
  "name": "Best Radio 92.6",
  "en_name": "Best Radio 92.6",
  "alias": "best-radio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://best.live24.gr/best1222",
  "cover": "/assets/stations/thumb/athens/bestradio.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Black Man 103.1",
  "en_name": "Black Man 103.1",
  "alias": "blackman",
  "genre": "Λαϊκά",
  "region": "Αθήνα",
  "audio": "http://alpha-stream.eu:9326/;.mp3",
  "cover": "/assets/stations/thumb/athens/blackman.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Blue Space Athens 107.2",
  "en_name": "Blue Space Athens 107.2",
  "alias": "blue-space-athens",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://91.121.222.81:8104/stream",
  "cover": "/assets/stations/thumb/athens/blue-space-1072.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Church of Greece 89.5",
  "en_name": "Church of Greece 89.5",
  "alias": "church-of-greece",
  "genre": "Θρησκευτική",
  "region": "Αθήνα",
  "audio": "http://85.10.206.222:8002/;listen.pls",
  "cover": "/assets/stations/thumb/athens/church-of-greece.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Church of Piraeus 91.2",
  "en_name": "Church of Piraeus 91.2",
  "alias": "church-of-piraeus",
  "genre": "Θρησκευτική",
  "region": "Αθήνα",
  "audio": "https://impradio.bytemasters.gr/8002/stream",
  "cover": "/assets/stations/thumb/athens/church-of-piraeus.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Derti Web Radio",
  "en_name": "Derti Web Radio",
  "alias": "derti-radio",
  "genre": "Λαϊκά",
  "region": "Αθήνα",
  "audio": "https://derti.live24.gr/derty1000",
  "cover": "/assets/stations/thumb/athens/derti-web-radio.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Diesi 101.3",
  "en_name": "Diesi 101.3",
  "alias": "diesi",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://diesi.live24.gr/diesi1013",
  "cover": "/assets/stations/thumb/athens/diesi.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Ellada FM 94.3",
  "en_name": "Ellada FM 94.3",
  "alias": "ellada-fm",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/ellada943",
  "cover": "/assets/stations/thumb/athens/ellada-fm.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Ert Open 106.7",
  "en_name": "Ert Open 106.7",
  "alias": "ert-open",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/erasport",
  "cover": "/assets/stations/thumb/athens/ert-open-radio.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Galaxy 92 FM",
  "en_name": "Galaxy 92 FM",
  "alias": "galaxy-fm",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://galaxy.live24.gr/galaxy9292",
  "cover": "/assets/stations/thumb/athens/galaxy.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Groove Radio",
  "en_name": "Groove Radio",
  "alias": "groove-radio",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "http://213.239.206.179:8090/stream",
  "cover": "/assets/stations/thumb/athens/groove-radio.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Hot FM",
  "en_name": "Hot FM",
  "alias": "hot-fm",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://hotfm.live24.gr/hotfm",
  "cover": "/assets/stations/thumb/athens/hot-1046.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Join Radio",
  "en_name": "Join Radio",
  "alias": "join-radio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/2uh8e4akb",
  "cover": "/assets/stations/thumb/athens/join_radio.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Kiss FM 92.9",
  "en_name": "Kiss FM 92.9",
  "alias": "kiss-fm-athens",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://kissfm.live24.gr/kissfmathens",
  "cover": "/assets/stations/thumb/athens/kiss-fm.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Legend 88.6 FM",
  "en_name": "Legend 88.6 FM",
  "alias": "legend",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/886athens",
  "cover": "/assets/stations/thumb/athens/legend-886.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Liquid FM",
  "en_name": "Liquid FM",
  "alias": "liquid-fm",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://ice.streamcloud.mediacdn.com/freeradio-1000",
  "cover": "/assets/stations/thumb/athens/liquid-radio.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Love Radio 97.5",
  "en_name": "Love Radio 97.5",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://loveradio.live24.gr/loveradio-1000",
  "cover": "/assets/stations/thumb/athens/love-radio.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "MAD Radio 106.2",
  "en_name": "MAD Radio 106.2",
  "alias": "mad-radio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://mediaserver.mad.tv/stream",
  "cover": "/assets/stations/thumb/athens/mad-radio.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Μέντα 88 FM",
  "en_name": "Menta 88 FM",
  "alias": "menta",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/menta",
  "cover": "/assets/stations/thumb/athens/mentafm.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Metro Radio 89.2",
  "en_name": "Metro Radio 89.2",
  "alias": "metro-radio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://stream.crete.ovh:8443/metro",
  "cover": "/assets/stations/thumb/athens/metro-radio2.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Music 89.2",
  "en_name": "Music 89.2",
  "alias": "music-89",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/k0hua7u1yk5tv",
  "cover": "/assets/stations/thumb/athens/music-892.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Nostos 100.6",
  "en_name": "Nostos 100.6",
  "alias": "nostos",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "https://neos.win:37878/stream",
  "cover": "/assets/stations/thumb/athens/nostos-106.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Over FM 104.9",
  "en_name": "Over FM 104.9",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/overfm-internet",
  "cover": "/assets/stations/thumb/athens/over-fm.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Party FM 104",
  "en_name": "Party FM 104",
  "alias": "party-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://radio.streamings.gr:9006/stream",
  "cover": "/assets/stations/thumb/athens/party-fm.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Pepper 96.6",
  "en_name": "Pepper 96.6",
  "alias": "pepper",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "https://pepper966.live24.gr/pepperorigin",
  "cover": "/assets/stations/thumb/athens/pepper.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Piqeras DeeJay 105.6",
  "en_name": "Piqeras DeeJay 105.6",
  "alias": "piqeras-deejay",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://rosetta.shoutca.st:8270/stream",
  "cover": "/assets/stations/thumb/athens/piqeras-deejay.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Radio Gold @net",
  "en_name": "Radio Gold @net",
  "alias": "radio-gold",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "http://radiogold.live24.gr/radiogold",
  "cover": "/assets/stations/thumb/athens/radio-gold.bmp",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Radio Hot Lips Athens",
  "en_name": "Radio Hot Lips Athens",
  "alias": "radio-hot-lips",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/radiohotlips",
  "cover": "/assets/stations/thumb/athens/radio-hot-lips.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Real FM 97.8",
  "en_name": "Real FM 97.8",
  "alias": "real-fm-athens",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://realfm.live24.gr/realfm",
  "cover": "/assets/stations/thumb/athens/real-fm.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Sfera 102.2",
  "en_name": "Sfera 102.2",
  "alias": "sfera",
  "genre": "Ροκ",
  "region": "Αθήνα",
  "audio": "http://sfera.live24.gr/sfera4132",
  "cover": "/assets/stations/thumb/athens/sfera-athens.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Smooth 99.8 Radio",
  "en_name": "Smooth 99.8 Radio",
  "alias": "smooth-radio",
  "genre": "Δημοφιλή",
  "region": "Αθήνα",
  "audio": "http://iphone.live24.gr/radio998",
  "cover": "/assets/stations/thumb/athens/smooth998.png",
  "encrypted": false,
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Sport 24 Radio 93.3",
  "en_name": "Sport 24 Radio 93.3",
  "alias": "sport24-radio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://sport24radio.live24.gr/sport24radio",
  "cover": "/assets/stations/thumb/athens/sport24.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Sport FM 94.6",
  "en_name": "Sport FM 94.6",
  "alias": "sport-fm",
  "genre": "Αθλητικά",
  "region": "Αθήνα",
  "audio": "https://sportfm.live24.gr/sportfm7712",
  "cover": "/assets/stations/thumb/athens/sportfm946.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Star Athens 107.8",
  "en_name":"Star Athens 107.8",
  "alias": "star-athens",
  "genre": "Αθλητικά",
  "region": "Αθήνα",
  "audio": "http://95.154.254.129:17498/stream",
  "cover": "/assets/stations/thumb/athens/star-athens.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Αθήνα 98.4",
  "en_name": "Athina 98.4",
  "alias": "athina-98",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://athina984.live24.gr/athina984",
  "cover": "/assets/stations/thumb/athens/athina-984.jfif",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Δρόμος 89.8 FM",
  "en_name": "Dromos 89.8 FM",
  "alias": "dromos-fm",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://dromos898.live24.gr/dromos898",
  "cover": "/assets/stations/thumb/athens/dromosfm.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Ελληνικός FM 93.2",
  "en_name": "Ellinikos FM 93.2",
  "alias": "ellinikos-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://orange.live24.gr/orange9320",
  "cover": "/assets/stations/thumb/athens/ellinikos932.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Επικοινωνία FM 94.0",
  "en_name": "Epikoinwnia FM 94.0",
  "alias": "epikoinonia-fm",
  "genre": "Λαϊκά",
  "region": "Αθήνα",
  "audio": "http://172.93.237.106:8274/;stream.mp3",
  "cover": "/assets/stations/thumb/athens/Epikoinwnia.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΑ σπορ 101.8",
  "en_name": "ERA Spor 101.8",
  "alias": "era-spor",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "http://radiostreaming.ert.gr/ert-erasport",
  "cover": "/assets/stations/thumb/athens/era-spor-1018.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΤ Δεύτερο πρόγραμμα 103.7",
  "en_name": "ERT Deytero Programma 103.7",
  "alias": "ert-deytero-programma",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://radiostreaming.ert.gr/ert-deftero",
  "cover": "/assets/stations/thumb/athens/ert-2.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΤ Κόσμος 93.6",
  "en_name": "ERT Kosmos 93.6",
  "alias": "ert-kosmos",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://radiostreaming.ert.gr/ert-kosmos",
  "cover": "/assets/stations/thumb/athens/ert-kosmos.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΤ Πρώτο πρόγραμμα 91.6",
  "en_name": "ERT Prwto Programma 91.6",
  "alias": "ert-proto-programma",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://radiostreaming.ert.gr/ert-proto",
  "cover": "/assets/stations/thumb/athens/ert-1.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΤ Τρίτο πρόγραμμα 90.9",
  "en_name": "ERT Trito Programma 90.9",
  "alias": "ert-trito-programma",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "http://radiostreaming.ert.gr/ert-trito",
  "cover": "/assets/stations/thumb/athens/ert-3.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Θέμα Radio 104.6",
  "en_name": "Thema Radio 104.6",
  "alias": "thema-radio",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/cdk030dsvv5tv",
  "cover": "/assets/stations/thumb/athens/thema.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Κανάλι 1 90.4",
  "en_name": "Kanali 1 90.4",
  "alias": "kanali-1",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/kanali1peiraia",
  "cover": "/assets/stations/thumb/athens/kanali-ena.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Κρήτη FM 87.5",
  "en_name": "Kriti FM 87.5",
  "alias": "kriti-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://s3.onweb.gr:8878/;listen.pls",
  "cover": "/assets/stations/thumb/athens/kriti-fm.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "LiveTalks",
  "en_name": "LiveTalks",
  "alias": "livetalks",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/livetalks",
  "cover": "/assets/stations/thumb/athens/livetalks.webp",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Λύρα FM 91.4",
  "en_name": "Lyra FM 91.4",
  "alias": "lyra-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://172.93.237.106:8271/stream",
  "cover": "/assets/stations/thumb/athens/lyra-fm.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Μινόρε FM ",
  "en_name": "Minore FM",
  "alias": "minore-fm",
  "genre": "Δημοφιλή",
  "region": "Αθήνα",
  "audio": "https://minorefm.live24.gr/minorefm",
  "cover": "/assets/stations/thumb/athens/minore-gr.gif",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Μουσικός 98.6",
  "en_name": "Mousikos 98.6",
  "alias": "mousikos-98",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "https://netradio.live24.gr/mousikos986",
  "cover": "/assets/stations/thumb/athens/mousikos-986.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Παραπολιτικά FM 90.1",
  "en_name": "Parapolitika FM 90.1",
  "alias": "parapolitika-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://netradio.live24.gr/athinaradio",
  "cover": "/assets/stations/thumb/athens/parapolitika-fm.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Πειρατικός FM 99.5",
  "en_name": "Peiratikos FM 99.5",
  "alias": "peiratikos-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Αθήνα",
  "audio": "http://89.163.217.252:9800/;",
  "cover": "/assets/stations/thumb/athens/peiratikos-fm.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ράδιο Βερόνικα",
  "en_name": "Radio Veronika",
  "alias": "radio-veronika",
  "genre": "Λαϊκά",
  "region": "Αθήνα",
  "audio": "http://radioveronica.live24.gr/veronicaweb",
  "cover": "/assets/stations/thumb/athens/veronica.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ραδιοζώνες 93.8",
  "en_name": "Radiozwnes 93.8",
  "alias": "radiozones",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "http://live.stream.radio98fm.org:8000/high",
  "cover": "/assets/stations/thumb/athens/radiozones.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "ΣΚΑΪ 100.3",
  "en_name": "Skai 100.3",
  "alias": "skai",
  "genre": "Ειδήσεις",
  "region": "Αθήνα",
  "audio": "https://skai.live24.gr/skai1003-2",
  "cover": "/assets/stations/thumb/athens/skai.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Στο κόκκινο 105.5",
  "en_name": "Sto Kokkino 105.5",
  "alias": "sto-kokkino-athens",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://stream.radiojar.com/kokkino-ath.mp3",
  "cover": "/assets/stations/thumb/athens/sto-kokkino.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Χριστιανισμός 104.3",
  "en_name": "Xristianismos 104.3",
  "alias": "xristianismos",
  "genre": "Θρησκευτική",
  "region": "Αθήνα",
  "audio": "http://eu1.fastcast4u.com:9326/stream",
  "cover": "/assets/stations/thumb/athens/xristianismos-fm.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Xradio",
  "en_name": "Xradio",
  "alias": "xradio",
  "genre": "Διεθνή",
  "region": "Αθήνα",
  "audio": "https://cast.radiohost.ovh/xradio?mp=/stream/",
  "cover": "/assets/stations/thumb/athens/xradio.png",
  "favorite": false,
  "id": uuidv4()   
  },
  {
  "name": "1055 Rock 105.5",
  "en_name": "1055 Rock 105.5",
  "alias": "1055-rock",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "http://radio.1055rock.gr:30000/1055",
  "cover": "/assets/stations/thumb/thessaloniki/rock-1055.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "88μισό 88.5",
  "en_name": "88miso",
  "alias": "88miso",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://solid33.streamupsolutions.com:30000/stream",
  "cover": "/assets/stations/thumb/thessaloniki/radio-805.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "89 Rainbow",
  "en_name": "89 Rainbow",
  "alias": "rainbow",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "http://62.212.82.197:8000/stream",
  "cover": "/assets/stations/thumb/thessaloniki/rainbow.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "9.58 ΕΡΤ3 95.8",
  "en_name": "9.58 ΕΡΤ3 95.8",
  "alias": "ert3",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://radiostreaming.ert.gr/ert-958fm",
  "cover": "/assets/stations/thumb/thessaloniki/ert-3.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "99 FM / ράδιο1",
  "en_name": "99 FM / ράδιο1",
  "alias": "99fm-radio1",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://62.212.82.208:8000/stream",
  "cover": "/assets/stations/thumb/thessaloniki/radio-1.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Alpha 96.5 Θεσσαλονίκη",
  "en_name": "Alpha 96.5 Thessaloniki",
  "alias": "alpha-thessaloniki",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://46.4.121.138:7700/alpha",
  "cover": "/assets/stations/thumb/thessaloniki/alpha-965.jpg",
  "favorite": false,
  "encrypted": false, 
  "id": uuidv4()
  },
  {
  "name": "Ble.FM 93.1",
  "en_name": "Ble.FM 93.1",
  "alias": "ble-fm",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://radio.lancom.gr:8006/stream1",
  "cover": "/assets/stations/thumb/thessaloniki/ble-fm.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "City International 106.1",
  "en_name": "City International 106.1",
  "alias": "city-international",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://radio.lancom.gr:8012/stream5",
  "cover": "/assets/stations/thumb/thessaloniki/city-1061.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Cosmoradio 95.1",
  "en_name": "Cosmoradio 95.1",
  "alias": "cosmoradio",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "https://eco.onestreaming.com:18812/stream",
  "cover": "/assets/stations/thumb/thessaloniki/kosmoradio.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Flash 99.4",
  "en_name": "Flash 99.4",
  "alias": "flash",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://207.154.248.204:8009/stream",
  "cover": "/assets/stations/thumb/thessaloniki/flash.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Fly 104",
  "en_name": "Fly 104",
  "alias": "fly",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "https://eu9.fastcast4u.com/proxy/nicper00?mp=/1",
  "cover": "/assets/stations/thumb/thessaloniki/fly3.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "FM 100 Δημοτικό Ραδιόφωνο Θεσσαλονίκης",
  "en_name": "FM 100 Dimotiko Radiofono Thessalonikis",
  "alias": "fm-100",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "https://live.fm100.gr/shoutcast/fm100",
  "cover": "/assets/stations/thumb/thessaloniki/fm-100.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "FM 100.6 Δημοτικό Ραδιόφωνο Θεσσαλονίκης",
  "en_name": "FM 100.6 Dimotiko Radiofono Thessalonikis",
  "alias": "fm-1006",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Θεσσαλονίκη",
  "audio": "https://live.fm100.gr/shoutcast/fm1006",
  "cover": "/assets/stations/thumb/thessaloniki/fm-1006.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Focus FM 103.6",
  "en_name": "Focus FM 103.6",
  "alias": "focus-fm",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://ice.greekstream.net/focusfm",
  "cover": "/assets/stations/thumb/thessaloniki/focus.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Fresh Salad 96.1",
  "en_name": "Fresh Salad 96.1",
  "alias": "fresh-salad",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://178.33.135.246:5262/stream",
  "cover": "/assets/stations/thumb/thessaloniki/fresh-radio.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Imagine 89.7",
  "en_name": "Imagine 89.7",
  "alias": "imagine",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "https://eu9.fastcast4u.com/proxy/nicperet?mp=/1",
  "cover": "/assets/stations/thumb/thessaloniki/imagine-radio.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Karamela 92.4",
  "en_name": "Karamela 92.4",
  "alias": "karamela",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://sc.greekstream.net:4014/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/karamela.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Libero 107.4",
  "en_name": "Libero 107.4",
  "alias": "libero",
  "genre": "Αθλητικά",
  "region": "Θεσσαλονίκη",
  "audio": "http://eco.onestreaming.com:8081/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/libero.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Live FM 98.6",
  "en_name": "Live FM 98.6",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://167.114.246.249:8001/stream",
  "cover": "/assets/stations/thumb/thessaloniki/radio-live.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Love 106.5",
  "en_name": "Love 106.5",
  "alias": "love-thessaloniki",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://server.insms.gr:45104/;stream",
  "cover": "/assets/stations/thumb/thessaloniki/love-1065.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Metropolis 95.5",
  "en_name": "Metropolis 95.5",
  "alias": "metropolis",
  "genre": "Αθλητικά",
  "region": "Θεσσαλονίκη",
  "audio": "https://metropolis.live24.gr/metropolis955thess",
  "cover": "/assets/stations/thumb/thessaloniki/metropolis.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "More 103",
  "en_name": "More 103",
  "alias": "more-103",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://eco.onestreaming.com:8041/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/more-103.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
    "name": "Μουσικό Κανάλι 105.1",
    "en_name": "Mousiko Kanali 105.1",
    "alias": "mousiko-kanali",
    "genre": "Διεθνή",
    "region": "Αθήνα",
    "audio": "http://stream1.mousikokanali.gr:8100/mc?.mp3",
    "cover": "/assets/stations/thumb/athens/mousiko-kanali.jpg",
    "favorite": false,
    "encrypted": false,
    "id": uuidv4()
  },
  {
  "name": "Off Radio",
  "en_name": "Off Radio",
  "alias": "off-radio",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "http://46.28.53.118:7062/stream",
  "cover": "/assets/stations/thumb/thessaloniki/radio-off.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Plus Radio 102.6",
  "en_name": "Plus Radio 102.6",
  "alias": "plus-radio",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "https://plusradio.gr/stream1",
  "cover": "/assets/stations/thumb/thessaloniki/plus-1026.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Real FM 107.1",
  "en_name": "Real FM 107.1",
  "alias": "real-fm-thessaloniki",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "https://realfmthess.live24.gr/realthess",
  "cover": "/assets/stations/thumb/thessaloniki/real-fm.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Republic Radio",
  "en_name": "Republic Radio",
  "alias": "republic-radio",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "https://netradio.live24.gr/republicthess",
  "cover": "/assets/stations/thumb/thessaloniki/republic.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Rock Radio 104.7",
  "en_name": "Rock Radio 104.7",
  "alias": "rock-radio",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "http://188.165.248.218:8013/stream",
  "cover": "/assets/stations/thumb/thessaloniki/rock-radio.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "RSO 91.7",
  "en_name": "RSO 91.7",
  "alias": "rso",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://tachyon.shoutca.st:8594/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/rso.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Sohos FM 88.7",
  "en_name": "Sohos FM 88.7",
  "alias": "sohos-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Θεσσαλονίκη",
  "audio": "http://eu7.fastcast4u.com:2046/stream",
  "cover": "/assets/stations/thumb/thessaloniki/sohos.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Status FM 107.7",
  "en_name": "Status FM 107.7",
  "alias": "status-fm",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://148.251.179.119:8000/stream",
  "cover": "/assets/stations/thumb/thessaloniki/status.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Studio 3 103.5",
  "en_name": "Studio 3 103.5",
  "alias": "studio-3",
  "genre": "Λαϊκά",
  "region": "Θεσσαλονίκη",
  "audio": "http://s1.onweb.gr:8408/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/studio-3.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "V FM 91.1",
  "en_name": "V FM 91.1",
  "alias": "v-fm",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "https://cast5.magicstreams.gr:2200/sc/vfm/stream",
  "cover": "/assets/stations/thumb/thessaloniki/v-fm.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Velvet 96.8",
  "en_name": "Velvet 96.8",
  "alias": "velvet",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "https://netradio.live24.gr/velvet968thess",
  "cover": "/assets/stations/thumb/thessaloniki/velvet.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "We Radio 89.4",
  "en_name": "We Radio 89.4",
  "alias": "we-radio",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "https://radioservers.xyz/we-894-live",
  "cover": "/assets/stations/thumb/thessaloniki/we-894.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Yellow Radio 101.7",
  "en_name": "Yellow Radio 101.7",
  "alias": "yellow-radio",
  "genre": "Αθλητικά",
  "region": "Θεσσαλονίκη",
  "audio": "http://radio.streamings.gr:8009/stream",
  "cover": "/assets/stations/thumb/thessaloniki/yellow.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Zoo Radio 90.8",
  "en_name": "Zoo Radio 90.8",
  "alias": "zoo-radio",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://netradio.live24.gr/zoo908thess",
  "cover": "/assets/stations/thumb/thessaloniki/zoo.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "ΕΡΤ3-Μακεδονίας 102",
  "en_name": "Ert3-Makedonias 102",
  "alias": "ert3-makedonias",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "https://radiostreaming.ert.gr/ert-102fm",
  "cover": "/assets/stations/thumb/thessaloniki/ert-3.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ερωτικός FM 94.8",
  "en_name": "Erotikos FM 94.8",
  "alias": "erotikos-fm",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Θεσσαλονίκη",
  "audio": "http://eu9.fastcast4u.com:5282/;",
  "cover": "/assets/stations/thumb/thessaloniki/erotikos.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Λυδία η Φιλιππησία 94.2",
  "en_name": "Lydia i Filippisia 94.2",
  "alias": "lydia-philippisia",
  "genre": "Θρησκευτική",
  "region": "Θεσσαλονίκη",
  "audio": "http://eu1.radiolydia.com:1086/stream",
  "cover": "/assets/stations/thumb/thessaloniki/lydia.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Μύθος FM 93.4",
  "en_name": "Mythos FM 93.4",
  "alias": "mythos-fm",
  "genre": " 'Εντεχνα",
  "region": "Θεσσαλονίκη",
  "audio": "http://s2.free-shoutcast.com:18560/stream",
  "cover": "/assets/stations/thumb/thessaloniki/mythos.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ορθόδοξη Παρουσία 106.8",
  "en_name": "Orthodoksi Parousia 106.8",
  "alias": "orthodoksi-parousia",
  "genre": "Θρησκευτική",
  "region": "Θεσσαλονίκη",
  "audio": "http://www.gwebstream.net:8000/stream",
  "cover": "/assets/stations/thumb/thessaloniki/orthodoxi-parousia.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Πρακτορείο 104.9",
  "en_name": "Praktoreio 104.9",
  "genre": "Έντεχνα / Παραδοσιακά",
  "alias": "praktoreio",
  "region": "Θεσσαλονίκη",
  "audio": "http://s3.onweb.gr:8436/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/praktoreio.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ράδιο Ακρίτες 102.3",
  "en_name": "Radio Akrites 102.3",
  "alias": "radio-akrites",
  "genre": "Έντεχνα / Παραδοσιακά",
  "region": "Θεσσαλονίκη",
  "audio": "http://akritespontou.live24.gr/akritespontou",
  "cover": "/assets/stations/thumb/thessaloniki/radio-akrites.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ράδιο Άποψη",
  "en_name": "Radio Apopsi",
  "alias": "radio-apopsi",
  "genre": "Διεθνή",
  "region": "Θεσσαλονίκη",
  "audio": "http://eco.onestreaming.com:8398/;",
  "cover": "/assets/stations/thumb/thessaloniki/radioapopsi.jpg",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ράδιο Έκρηξη 99.8",
  "en_name": "Radio Ekriksi 99.8",
  "alias": "radio-ekriksi",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://sc.greekstream.net:4006/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/radio-ekriksi.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Ράδιο Θεσσαλονίκη 94.5",
  "en_name": "Radio Thessaloniki 94.5",
  "alias": "radio-thessaloniki",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://eu7.fastcast4u.com:6156/stream?type=http&nocache=136596",
  "cover": "/assets/stations/thumb/thessaloniki/radio-945.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ραδιοκύματα 104.4",
  "en_name": "Radiokymata 104.4",
  "alias": "radiokimata",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "http://s1.onweb.gr:8812/;listen.pls",
  "cover": "/assets/stations/thumb/thessaloniki/radiokymata-1044.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Ραδιόφωνο 904 90.4",
  "en_name": "Radiofono 90.4",
  "alias": "radiofono-904",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "http://icecast.902.gr:8000/904",
  "cover": "/assets/stations/thumb/thessaloniki/radio-904.png",
  "favorite": false,
  "encrypted": false,
  "id": uuidv4()
  },
  {
  "name": "Στο κόκκινο 91.4",
  "en_name": "Sto Kokkino",
  "alias": "sto-kokkino-thessaloniki",
  "genre": "Ειδήσεις",
  "region": "Θεσσαλονίκη",
  "audio": "https://stream.radiojar.com/275p8ssmb?",
  "cover": "/assets/stations/thumb/thessaloniki/sto-kokkino.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Στο Μωβ",
  "en_name": "Sto Mov",
  "alias": "sto-mov",
  "genre": "Ροκ",
  "region": "Θεσσαλονίκη",
  "audio": "https://stream.radiojar.com/kk01hepkb.mp3",
  "cover": "/assets/stations/thumb/thessaloniki/sto-mov.png",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Τρανζίστορ 100.3",
  "en_name": "Tranzistor 100.3",
  "alias": "tranzistor",
  "genre": "Δημοφιλή",
  "region": "Θεσσαλονίκη",
  "audio": "https://netradio.live24.gr/tranzistor1003thess",
  "cover": "/assets/stations/thumb/thessaloniki/tranzistor.jpg",
  "favorite": false,
  "id": uuidv4()
  },
  {
  "name": "Χρώμα FM 105.8",
  "en_name": "Xroma FM 105.8",
  "alias": "xroma-fm",
  "genre": " 'Εντεχνα",
  "region": "Θεσσαλονίκη",
  "audio": "https://hroma1058thess.live24.gr/hroma1058thess",
  "cover": "/assets/stations/thumb/thessaloniki/xroma.png",
  "favorite": false,
  "id": uuidv4()
  }
];
}

export default chillHop;
