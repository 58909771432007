import React, {useState} from "react";
import CarLibraryStation from "./CarLibraryStation";
import CarPlayer from "./CarPlayer";
import {_track} from "../util"; 
import {Link} from "react-router-dom";
import {normalizeGreek} from "../util";
var _ = require('lodash');

const CarLibrary = ({
  stations,
  setStations,
  searchQuery,
  setSearchQuery,
  currentStation,
  setCurrentStation,
  genreFilter,
  regionFilter,
  setRegionFilter, 
  setGenreFilter,
  showGenreFilter,
  setShowGenreFilter,
  showRegionFilter,
  setShowRegionFilter,
  stationInfo,
  setStationInfo,
  audioRef,
  isPlaying,
  setIsPlaying,
  libraryStatus,
  setLibraryStatus,
}) => {
  
  const [showFilters, setShowFilters] = useState(false);
  const [scrollDepth, setScrollDepth] = useState(0);

  const clearFiltersHandler = () => {
    const genreButton = document.querySelector("#filter-genre-btn"); 
    const regionButton = document.querySelector("#filter-region-btn"); 
    regionButton.innerText = "Περιοχή"
    genreButton.innerText = "Είδος";
    _track('click_clear_filters', {});
    setRegionFilter(null);
    setGenreFilter(null);
  }

  const hamburgerHandler = (e) => {
    setLibraryStatus(!libraryStatus);
    _track('click_close_library', {});
  }

  const scrollHandler = (e) => {
    setScrollDepth(e.target.scrollTop);
  }

  const scrollTopHandler = (e) => {
    var library = document.querySelector('div.car-library-stations'); 
    library.scrollTop = 0;
  }

  var filteredStations = _.map(stations, function(station) {
      const stationName = station.name.toLowerCase();
      const stationEnName = station.en_name ? station.en_name.toLowerCase() : null;

      if ((_.includes(stationName, searchQuery) 
        || _.includes(normalizeGreek(stationName), searchQuery)
        || (stationEnName && _.includes(stationEnName, searchQuery)))   
        && (genreFilter===null || _.eq(station.genre,genreFilter))
        && (regionFilter===null || _.eq(station.region, regionFilter)))
          return station;
  }); 
  filteredStations = _.without(filteredStations, undefined);
  filteredStations = _.orderBy(filteredStations, ['favorite','en_name'],['desc','asc']);

  return (
    <div className={`car-library ${libraryStatus ? "car-active-library" : " "}`}>
      <div className={`car-library-top-section ${showFilters ? "car-active-filters" : " "}`}>
        <div className="car-logo-section">
        </div>
      </div>
      <div 
        className={`car-no-results ${filteredStations.length === 0 ? "" : "hide"}`}
      >
        <h2>Δε βρέθηκαν σταθμοί με βάση τα κριτήρια αναζήτησης.        
          <a 
            href="/#" 
            onClick={clearFiltersHandler}
            className={`${genreFilter == null && regionFilter == null ? "hide" : ""}`}
          > Καθαρισμός φίλτρων αναζήτησης.
        </a>
        </h2>
      </div>
      <div 
        className="car-library-stations"
        onScroll={scrollHandler}
      >
        {filteredStations.map((station) => (
          <Link to={`/car/station/${station.alias == null ? "undefined" : station.alias}`}>
          <CarLibraryStation
              searchQuery={searchQuery}
              stations={stations}
              setStations={setStations}
              cover={station.cover}
              name={station.name}
              genre={station.genre}
              active={station.active}
              key={station.id}
              id={station.id}
              setCurrentStation={setCurrentStation}
              stationInfo={stationInfo}
              setStationInfo={setStationInfo}
              audioRef={audioRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              favorite={station.favorite}
            />
          </Link>
        ))}
      <div className="car-footer">
        <CarPlayer 
            audioRef={audioRef}
            setIsPlaying={setIsPlaying}
            currentStation={currentStation}
            isPlaying={isPlaying}
            stationInfo={stationInfo}
            setStationInfo={setStationInfo}
            stations={stations}
            setStations={setStations}
            setCurrentStation={setCurrentStation}       
            searchQuery={searchQuery}
            libraryStatus={libraryStatus}
            setLibraryStatus={setLibraryStatus}
        >
        </CarPlayer>  
      </div>  
      </div>    
    </div>
  );
};

export default CarLibrary;
