import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faAngleLeft,
  faAngleRight,
  faPause,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons";
import {playAudio, _track} from "../util";

const Player = ({
  isPlaying,
  setIsPlaying,
  audioRef,
  stationInfo,
  setStationInfo,
  currentStation,
  stations,
  setCurrentStation,
  setStations,
}) => {
  //const [activeVolume, setActiveVolume] = useState(false);
  //UseEffect Update List
  const activeLibraryHandler = (nextPrev) => {
    const newStations = stations.map((station) => {
      if (station.id === nextPrev.id) {
        return {
          ...station,
          active: true,
        };
      } else {
        return {
          ...station,
          active: false,
        };
      }
    });
    setStations(newStations);
  };

  const trackAnim = {
    //transform: `translateX(${songInfo.animationPercentage}%)`,
  };
  //Event Handlers
  function getTime(time) {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }
  /*const dragHandler = (e) => {
    audioRef.current.currentTime = e.target.value;
    setSongInfo({ ...songInfo, currentTime: e.target.value });
  };*/

  const playStationHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
      _track('click_pause_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      setIsPlaying(!isPlaying);
    } else {
      audioRef.current.load();
      audioRef.current.play();
      _track('click_play_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      setIsPlaying(!isPlaying);
    }
  };
  const skipTrackHandler = async (direction) => {
    let currentIndex = stations.findIndex((station) => station.id === currentStation.id);

    //Forward BAck
    if (direction === "skip-forward") {
      _track('click_skipforward_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      await setCurrentStation(stations[(currentIndex + 1) % stations.length]);
      activeLibraryHandler(stations[(currentIndex + 1) % stations.length]);
    }
    if (direction === "skip-back") {
      _track('click_skipback_station', {
        'station':currentStation.name,
        'favorite':currentStation.favorite,
        'genre':currentStation.genre,
        'region':currentStation.region,
        'station_id':currentStation.id,
      });
      if ((currentIndex - 1) % stations.length === -1) {
        await setCurrentStation(stations[stations.length - 1]);
        activeLibraryHandler(stations[stations.length - 1]);
        playAudio(isPlaying, audioRef);
        return;
      }
      await setCurrentStation(stations[(currentIndex - 1) % stations.length]);
      activeLibraryHandler(stations[(currentIndex - 1) % stations.length]);
    }
    if (isPlaying) audioRef.current.play();
  };
  /*const changeVolume = (e) => {
    let value = e.target.value;
    audioRef.current.volume = value;
    setSongInfo({ ...songInfo, volume: value });
  };*/

  return (
    <div className="player">
      <div className="time-control">
        <p>{getTime(stationInfo.currentTime)}</p>
        <div
          //style={{
          //  background: `linear-gradient(to right, ${currentSong.color[0]},${currentSong.color[1]})`,
          //}}
          className="track"
        >
          <input
            value={stationInfo.currentTime}
            type="range"
            max={stationInfo.duration || 0}
            min={0}
            readOnly
          />
          <div style={trackAnim} className="animate-track"></div>
        </div>
        <p>&#8734;</p>
        {/*<p>{songInfo.duration ? getTime(songInfo.duration) : "0:00"}</p>*/}
      </div>
      <div className="play-control">
        <FontAwesomeIcon
          onClick={() => skipTrackHandler("skip-back")}
          className="skip-back"
          size="2x"
          icon={faAngleLeft}
        />
        <FontAwesomeIcon
          onClick={playStationHandler}
          className={`play ${isPlaying && stationInfo.currentTime === 0 ? "fa-spin" : " "}`} 
          size="2x"
          icon={isPlaying ? (stationInfo.currentTime === 0 ? faCircleNotch : faPause) : faPlay}
        />
        <FontAwesomeIcon
          className="skip-forward"
          size="2x"
          icon={faAngleRight}
          onClick={() => skipTrackHandler("skip-forward")}
        />{/*
        <FontAwesomeIcon
          onClick={() => setActiveVolume(!activeVolume)}
          icon={faVolumeDown}
        />
        {/*activeVolume && (
          <input
            onChange={changeVolume}
            value={songInfo.volume}
            max="1"
            min="0"
            step="0.01"
            type="range"
          />
        )*/} 
      </div>
    </div>
  );
};

export default Player;
