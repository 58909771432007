import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {_track} from "../util"; 

const Nav = ({ setLibraryStatus, libraryStatus }) => {

  const hamburgerHandler = (e) => {
    setLibraryStatus(!libraryStatus);
    _track('click_open_library', {});
  }

  return (
    <nav>
      <FontAwesomeIcon
        onClick={hamburgerHandler}
        className={!libraryStatus ? "hamburger" : "hide"}
        icon={faBars}
      />
      <div className="logo"> 
        <h1>Fast Radio</h1>
        <h2>Το ραδιοφωνικό σου στέκι στο Διαδίκτυο</h2>
      </div>
    </nav>
  );
};

export default Nav;
