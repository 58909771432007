//Import Components
import React, { useState, useEffect, useRef } from "react";
import "./styles/app.scss";
import Player from "./components/Player";
import MobilePlayer from "./components/MobilePlayer";
import Station from "./components/Station";
import Library from "./components/Library";
import CarLibrary from "./components/CarLibrary";
import CarPlayer from "./components/CarPlayer";
import Nav from "./components/Nav";
import PreloadStations from "./components/PreloadStations";
import {useHistory} from 'react-router-dom';
import {Switch, Route} from 'react-router-dom';


//Import data
import chillhop from "./data";
//Util
import {playAudio} from "./util";
var _ = require('lodash');
//Consts
const version = "0.3.1";
const libraryVersion = "0.2.42";

function App() {
  //Ref
  const audioRef = useRef(null);
  const history = useHistory();
  //States
  const [stations, setStations] = useState(chillhop());
  const [currentStation, setCurrentStation] = useState(stations[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [libraryStatus, setLibraryStatus] = useState(true);
  const [regionFilter, setRegionFilter] = useState(null);
  const [genreFilter, setGenreFilter] = useState(null);
  const [showGenreFilter, setShowGenreFilter] = useState(false);
  const [showRegionFilter, setShowRegionFilter] = useState(false);  
  const [isPlaying, setIsPlaying] = useState(false);
  const [stationInfo, setStationInfo] = useState({
    currentTime: 0,
    duration: 0,
    animationPercentage: 0,
    volume: 0,
  });
  
  //useEffect
  useEffect(() => {
    var storedStations = [];
    //Retrieve stations, either from localstorage or app memory
    if (localStorage.getItem("stations") !== null 
      && localStorage.getItem("library-version") !== null
      && localStorage.getItem("library-version") === libraryVersion) {
        storedStations = JSON.parse(localStorage.getItem("stations"));
    }
    else {
      storedStations = chillhop();
    }
    //Retrieve favorite stations
    if (localStorage.getItem("favorite-stations") !== null) {
      const storedFavoriteStations = JSON.parse(localStorage.getItem("favorite-stations"));
      storedFavoriteStations.map((favoriteStation) => {
        storedStations = storedStations.map((station) => {          
          if(favoriteStation.name === station.name) {
            return {
              ...station,
              favorite:true,
            } 
          }
          else {
            return station;
    }})})}
    storedStations = _.orderBy(storedStations, ['favorite','en_name'],['desc','asc']);
    //Retrieve preset station from url 
    const path = history.location.pathname.substring(9);
    var presetStation = storedStations[0]; 
    storedStations = storedStations.map((station) => {
      if (station.alias && path === station.alias) {
        presetStation = station;
        return {
          ...station,
          active: true,
        };
      } else {
        return {
          ...station,
          active: false,
        };
      }
    });
    setStations(storedStations);
    setCurrentStation(presetStation);
    localStorage.setItem("version", version);
    localStorage.setItem("library-version", libraryVersion) 
  },[])

  useEffect(() => {   
    localStorage.setItem("stations", JSON.stringify(stations));
    const favorites = _.filter(stations, ['favorite', true]);
    localStorage.setItem("favorite-stations", JSON.stringify(favorites));
  },[stations])

  useEffect(() => {
    return  history.listen((location) => {   

      if(history.action === "POP") {

        const path = history.location.pathname.substring(9);
        console.log(path);
        var newStations = [];
        var presetStation = stations[0];
        newStations = stations.map((station) => {
          if (station.alias && path === station.alias) {
            presetStation = station;
            return {
              ...station,
              active: true,
            };
          } else {
            return {
              ...station,
              active: false,
            };
          }
        });  

        //Retrieve favorite stations
        if (localStorage.getItem("favorite-stations") !== null) {
          const storedFavoriteStations = JSON.parse(localStorage.getItem("favorite-stations"));
          storedFavoriteStations.map((favoriteStation) => {
            newStations = newStations.map((station) => {          
          if(favoriteStation.name === station.name) {
            return {
              ...station,
              favorite:true,
            } 
          }
          else {
            return station;
        }})})}

        newStations = _.orderBy(newStations, ['favorite','en_name'],['desc','asc']);
        setStations(newStations);      
        setCurrentStation(presetStation);
        playAudio(isPlaying, audioRef);

        setTimeout(() => {
          var selected = document.querySelector('div.library-station.selected'); 
          if (selected) //TODO: Improve offset value control
            selected.parentElement.parentElement.scrollTop = selected.offsetTop-130; 
          }, 100);  
      } 
    }) 
  },[history])  
  
  useEffect(() => {
    if (currentStation != null && isPlaying) {
      document.title = currentStation.name;
    }
    else {
      document.title = "FastRadio.gr";
    }
  })

  useEffect(() => {
    setTimeout(() => {
      var selected = document.querySelector('div.library-station.selected'); 
      if (selected) //TODO: Improve offset value control
        selected.parentElement.parentElement.scrollTop = selected.offsetTop-130; 
    }, 500);  
   },[])

  const timeUpdateHandler = (e) => {
    const current = e.target.currentTime;
    const duration = e.target.duration;
    const roundedCurrent = Math.round(current);
    const roundedDuration = Math.round(duration);
    const percentage = Math.round((roundedCurrent / roundedDuration) * 100);
    setStationInfo({
      ...stationInfo,
      currentTime: current,
      duration: duration,
      animationPercentage: percentage,
      volume: e.target.volume,
    });
  };

  const stationEndHandler = async () => {
    let currentIndex = stations.findIndex((station) => station.id === currentStation.id);
    await setCurrentStation(stations[(currentIndex + 1) % stations.length]);
    playAudio(isPlaying, audioRef);
    return;
  };

  const globalClickHandler = (e) => {    
    if (e.target.id !== "filter-genre-btn")
      setShowGenreFilter(false);
    if (e.target.id !== "filter-region-btn") 
      setShowRegionFilter(false);   
  }

  return (

    <div 
      className={`App ${libraryStatus ? "library-active" : ""}`}
      onClick={globalClickHandler}
    >
    <Switch>

    <Route path={["/car/","/car/station/"]}>
        <CarLibrary
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          stations={stations}
          setStations={setStations}
          currentStation={currentStation}
          setCurrentStation={setCurrentStation}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
          genreFilter={genreFilter}
          setGenreFilter={setGenreFilter}
          showGenreFilter={showGenreFilter}
          setShowGenreFilter={setShowGenreFilter}
          showRegionFilter={showRegionFilter}
          setShowRegionFilter={setShowRegionFilter}
          stationInfo={stationInfo}
          setStationInfo={setStationInfo}
          audioRef={audioRef}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          libraryStatus={libraryStatus}
          setLibraryStatus={setLibraryStatus}
        />
    </Route>
    <Route path={["/","/station/"]}>
      <div className="main-section">
        <Library
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          stations={stations}
          setStations={setStations}
          setCurrentStation={setCurrentStation}
          regionFilter={regionFilter}
          setRegionFilter={setRegionFilter}
          genreFilter={genreFilter}
          setGenreFilter={setGenreFilter}
          showGenreFilter={showGenreFilter}
          setShowGenreFilter={setShowGenreFilter}
          showRegionFilter={showRegionFilter}
          setShowRegionFilter={setShowRegionFilter}
          stationInfo={stationInfo}
          setStationInfo={setStationInfo}
          audioRef={audioRef}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          libraryStatus={libraryStatus}
          setLibraryStatus={setLibraryStatus}
        />
        <div className="main-container">
          <Nav 
            libraryStatus={libraryStatus}
            setLibraryStatus={setLibraryStatus}
          />
          <Station 
            searchQuery={searchQuery}
            isPlaying={isPlaying} 
            stations={stations}
            setStations={setStations}
            currentStation={currentStation}
            stationInfo={stationInfo} 
          />
          <Player
            audioRef={audioRef}
            setIsPlaying={setIsPlaying}
            currentStation={currentStation}
            isPlaying={isPlaying}
            stationInfo={stationInfo}
            setStationInfo={setStationInfo}
            stations={stations}
            setStations={setStations}
            setCurrentStation={setCurrentStation}
          />
          <audio
            onLoadedMetadata={timeUpdateHandler}
            onTimeUpdate={timeUpdateHandler}
            ref={audioRef}
            src={currentStation.audio}
            onEnded={stationEndHandler}   
          ></audio>
        </div>
      </div>
      <div className="footer">
        <MobilePlayer 
            audioRef={audioRef}
            setIsPlaying={setIsPlaying}
            currentStation={currentStation}
            isPlaying={isPlaying}
            stationInfo={stationInfo}
            setStationInfo={setStationInfo}
            stations={stations}
            setStations={setStations}
            setCurrentStation={setCurrentStation}       
            searchQuery={searchQuery}
            libraryStatus={libraryStatus}
            setLibraryStatus={setLibraryStatus}
        >
        </MobilePlayer>  
      </div>
    </Route>
    </Switch>
    <audio
      onLoadedMetadata={timeUpdateHandler}
      onTimeUpdate={timeUpdateHandler}
      ref={audioRef}
      src={currentStation.audio}
      onEnded={stationEndHandler}   
    ></audio>
    <PreloadStations />
    </div>
  );
}

export default App;