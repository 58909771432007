export const playAudio = (isPlaying, audioRef) => {
  if (isPlaying) {
    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then((audio) => {
          audioRef.current.play();
        })
        .catch((error) => {
          console.log(error);
          setTimeout(() => {
            playAudio(isPlaying,audioRef);
          }, 2000);
        })
    }
  }
};

export const normalizeGreek = (text) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
};


export const _track = (eventName, properties) => {
  window.gtag(
    'event',
    eventName, 
    {'app_name': 'www.fastradio.gr',
     'station': properties['station'],
     'favorite': properties['favorite'],
     'genre': properties['genre'],
     'region': properties['region'],
     'station_id': properties['station_id'],
     'search_query': properties['search_query'],
     'genre_filter': properties['genre_filter'],
     'region_filter': properties['region_filter']
    }
  );
}

