import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

document.addEventListener("DOMContentLoaded", (e) => {
// Find selected div
//console.log(document.querySelector('div.library-station.selected'));
//var selected = document.querySelector('div.library-station.selected'); 
// Scroll container to offset of the selected div
//selected.parentElement.parentElement.scrollTop = selected.offsetTop;
//console.log(selected);
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
