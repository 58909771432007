import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {_track} from "../util"; 

const Search = ({
   searchQuery,
   setSearchQuery,
   showFilters,
   setShowFilters,
   libraryStatus,
   setLibraryStatus
}) => {

  const searchHandler = (e) => {  
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);    
  }

  const filterHandler = (e) => {  
    setShowFilters(!showFilters);
  }

  const cancelHandler = (e) => {
    const searchInput = document.querySelector("#search-box");
    searchInput.value = "";
    setSearchQuery(""); 
    _track('click_clear_query', {});
  }
 
  return (
      <div className="search">
        <FontAwesomeIcon
          className="search-icon"
          icon={faSearch}
        />
        <input type="search-box" 
          onChange={searchHandler}
          placeholder="Αναζήτηση Σταθμών..." 
          id="search-box"
          className="search-box" 
        />
        <FontAwesomeIcon
          onClick={filterHandler}
          className="filter-icon"
          icon={faCog}
        />
        <FontAwesomeIcon
          onClick={cancelHandler}
          className={`cancel-icon ${searchQuery === "" ? "hide" : " "}`}
          icon={faTimes}
        />

      </div>   
  );
};

export default Search;
