import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHeart as faHeartSolid} from "@fortawesome/free-solid-svg-icons"
import {faHeart} from "@fortawesome/free-regular-svg-icons"
import {_track} from "../util"; 
var _ = require('lodash');


const Station = ({ 
  searchQuery,
  stations, 
  setStations, 
  filteredStations, 
  setFilteredStations, 
  currentStation, 
  stationInfo,
  isPlaying
}) => {

  const favoriteStationHandler = () => {
    
    var newStations = [];

    if (currentStation.favorite === false) {
      currentStation.favorite = true;
      
      newStations = stations.map((station) => {
      if (currentStation.id === station.id) {
        return {
          ...station,
          favorite:true,
        }
      }
      else {
        return station;
      }
      })
  }
  else
  {
      currentStation.favorite = false;
      newStations = stations.map((station) => {
      if (currentStation.id === station.id) {
        return {
          ...station,
          favorite:false,
        }
      }
      else {
        return station;
      }
      })
  }
  _track('click_favorite_station', {
         'station':currentStation.name,
         'favorite':currentStation.favorite,
         'genre':currentStation.genre,
         'region':currentStation.region,
         'station_id':currentStation.id,
         'search_query':searchQuery
   });

  newStations = _.orderBy(newStations, ['favorite','en_name'],['desc','asc']);
  setStations(newStations);
  }

  return (
    <div className="station-container">
      
      <img
        className={isPlaying && stationInfo.currentTime > 0 ? "rotateStation" : ""}
        src={currentStation.cover}
        alt={currentStation.en_name}
      />
      <div className="station-name-container">
      <h2>{currentStation.name}</h2>      
      <h3>{currentStation.genre}</h3>
      <div className = "fav-container">
      <FontAwesomeIcon 
        onClick= {favoriteStationHandler}
        className= {`fav-icon ${currentStation.favorite ? "hide" : "show"}`}
        size="2x"
        icon={faHeart}
      />
      <FontAwesomeIcon
        onClick= {favoriteStationHandler}
        className= {`fav-icon-solid ${currentStation.favorite ? "show" : "hide"}`}         
        size="2x"
        icon={faHeartSolid}
      />  
      </div>
      </div>      
    </div>
  );
};

export default Station;
