import React from "react";
import { playAudio, _track} from "../util";

const CarLibraryStation = ({
  searchQuery,
  name,
  genre,
  cover,
  id,
  setCurrentStation,
  stations,
  setStations,
  audioRef,
  isPlaying,
  setIsPlaying,
  stationInfo,
  setStationInfo,
  active,
  favorite,
}) => {

  const stationSelectHandler = async () => {
    await audioRef.current.pause();
    await setStationInfo({...stationInfo,currentTime: 0,});
    const selectedStation = stations.filter((station) => station.id === id);
    //Set Active in library
    const newStations = stations.map((station) => {
      if (station.id === id) {
        return {
          ...station,
          active: true,
        };
      } else {
        return {
          ...station,
          active: false,
        };
      }
    });
    _track('click_select_station', {
         'station':selectedStation[0].name,
         'favorite':selectedStation[0].favorite,
         'genre':selectedStation[0].genre,
         'region':selectedStation[0].region,
         'station_id':selectedStation[0].id,
         'search_query':searchQuery
    });
    playAudio(true, audioRef);
    setIsPlaying(true);
    setCurrentStation({ ...selectedStation[0] });
    setStations(newStations);
  };
  return (
    <div
      onClick={stationSelectHandler}
      className={`car-library-station ${active ? "car-selected" : ""}`}
    >
    <div className="car-station-library-content">
    {/*<div className="now-playing">
      <FontAwesomeIcon
        className= {`now-playing-icon ${active ? "" : "hide"}`}    
        size="1x"
        icon={faCompactDisc}
  />
  </div>*/}
      <img src={cover} alt="" />
    </div>
    </div>
  );
};

export default CarLibraryStation;
